import Glide from '@glidejs/glide';

const carousel = {
  init() {
    const slides = document.querySelectorAll('.glide__slide');
    const el = document.getElementById('carousel');

    if (el) {
      let slideIndex = 0;
      const glide = new Glide(el, {
        type: 'slider',
        startAt: 0,
        perView: 3,
        gap: 24,
        rewind: false,
        breakpoints: {
          768: {
            perView: 1,
          },
          960: {
            perView: 2,
          }
        }
      })
      glide.on('mount.after', function() {
        const prev = document.querySelector('.glide__arrow--left')
        prev.disabled = true
      })

      glide.on('run.before', function(e) {
        const prev = document.querySelector('.glide__arrow--left')
        const next = document.querySelector('.glide__arrow--right')
        slideIndex = e.direction === '>' ? ++slideIndex : --slideIndex

        if (slideIndex === 0) {
          prev.disabled = true
        } else {
          prev.disabled = false
        }

        if (slideIndex === slides.length -1) {
          next.disabled = true
        } else {
          next.disabled = false
        }
      })

      glide.mount()
    }
  }
}

export default carousel;