import gsap from 'gsap';
const headerClass = 'submenu-is-open';
const buttonClass = 'submenu-button-is-active';
const menuClass = 'submenu-is-active';
const header = document.getElementById('header');

function hide(menu, removeHeaderClass = true) {
  // We might call this function when ajaxing pages so check if menu is defined
  // If not, find it
  let menuEl = menu || document.querySelector(`.${menuClass}`);
  const activeButton = document.querySelector(`.${buttonClass}`);
  if (activeButton) {
    activeButton.classList.remove(buttonClass);
  }
  
  gsap.to(menuEl, {
    height: 0,
    duration: 0.2,
    ease: 'none',
    onComplete: function () {
      if (menuEl) {
        const activeButton = document.querySelector(`.${buttonClass}`);
        menuEl.classList.remove(menuClass);
      }
      if (removeHeaderClass === true) {
        header.classList.remove(headerClass);
      }
    }
  });
}

function show(menu) {
  menu.classList.add(menuClass);
  if (menu.id !== 'menu-lang') {
    header.classList.add(headerClass);
  }
  const tl = gsap.timeline();
  tl.set(menu, {height: 'auto'})
  .from(menu, {
    height: 0,
    duration: 0.5,
    ease: 'power2.out',
    immediateRender: false
  });
}

function handle(button) {
  const selector = button.dataset.menu;
  const menu = document.getElementById(selector);
  const activeMenu = document.querySelector(`.${menuClass}`);
  
  if (activeMenu) {
    if (activeMenu !== menu) {
      const tl = gsap.timeline();
      tl.call(() => {
        hide(activeMenu, false);
        button.classList.add(buttonClass);
      })
      .call(() => {
        show(menu);
      }, null, '+=0.1');
    } else {
      hide(activeMenu);
    }
  } else {
    show(menu);
    button.classList.add(buttonClass);
  }
}

const dropdown = {
  init: function() {
    document.addEventListener('click', function(event) {
      const button = event.target.closest('.js-dropdown');
      if (button) {
        handle(button);
      }
    })
  },
  hide: hide
}

export default dropdown;
