import axios from 'axios';
let form;

const submitClass = 'form-is-submitting';
const successClass = 'form-submit-success';
const errorClass = 'form-submit-error';

function success() {
  if (window.grecaptcha) {
    window.grecaptcha.reset();
  }
  form.reset();
  form.classList.remove(submitClass);
  form.classList.add(successClass);
  window.setTimeout(() => {
    form.classList.remove(successClass);
  }, 5000);
}

function error(errors) {
  const els = Array.from(form.querySelectorAll('.js-form-error'));
  els.forEach(item => item.remove());
  form.classList.remove(submitClass);
  form.classList.add(errorClass);

  Object.keys(errors).forEach(item => {
    const input = form.querySelector(`*[name="${item}"]`);
    const el = document.createElement('p');
    el.innerHTML = errors[item];
    el.classList.add('form__error', 'js-form-error');

    if (input) {
      input.insertAdjacentElement('afterend', el);
    } else if (item === 'recaptcha') {
      const recaptchaEl = document.getElementById('form-recaptcha');
      recaptchaEl.append(el);
    }
  });
}

function handleSubmit() {
  form.classList.add(submitClass);
  form.classList.remove(successClass, errorClass);

  const data = new FormData(form);

  axios.post('/', data)
  .then(function(res) {
    if ('errors' in res.data) {
      error(res.data.errors);
    } else {
      success();
    }
  })
  .catch(function (error) {
    console.log(error);
    form.classList.remove(submitClass);
  });
}

export default function() {
  document.addEventListener('submit', function(e) {
    form = e.target.closest('#contact-form');
    if (form) {
      e.preventDefault();
      handleSubmit();
    }
  });
}

