import $ from 'jquery';
import 'slick-carousel'; 
// https://kenwheeler.github.io/slick/ for more info
    
const slickCarousel = {
  init() {
    $('.js-slick').each(function(elem) {
      let imageCount = parseInt($(this).attr('data-image-count')) || 1;
      let navType = $(this).attr('data-nav-type') || "dots";
      let thumbnails = $(this).next('.js-slick-thumbnails');
      
      $(this).slick({
        infinite: false,
        asNavFor: navType === "thumbnails" ? thumbnails : null,

        // navigation
        dots: navType === "dots",
        arrows: true,
        appendArrows: $('.js-slick-arrows'),
        prevArrow: '<div class="featured__arrow--left arrow--l"><svg viewBox="0 0 40 26" xmlns="http://www.w3.org/2000/svg"><g stroke="#18083F" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M3.314 13h36M13.314 1.686 2 13l11.314 11.314"></path></g></svg></div>',
        nextArrow: '<div class="featured__arrow--right arrow--l"><svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg"><g stroke="#18083F" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M36 12H0M26 .686 37.314 12 26 23.314"></path></g></svg></div>',

        // number of slides
        slidesToShow: imageCount,
        responsive: [
          {
            breakpoint: 787,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });

      if ( navType === "thumbnails" ) {
        thumbnails.slick({
          infinite: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          asNavFor: $(this),
          dots: false,
          arrows: false,
          centerMode: true,
          focusOnSelect: true
        });   
      }

    });
  }
}

export default slickCarousel;