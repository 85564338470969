import page from './modules/page';
import dropdown from './modules/dropdown';
import mobile from './modules/mobile';
import filter from './modules/filter';
import video from './modules/video';
import carousel from './modules/carousel';
import slickCarousel from './modules/slick';
import slideshow from './modules/slideshow';
import scroll from './modules/scroll';
import contact from './modules/contact';
import tabs from './modules/tabs';
import heroVideo from './modules/hero-video';
import cookie_consent from './modules/cookie_consent';
import chat from './modules/chat';
import form from './modules/form';

page();
heroVideo();
dropdown.init();
mobile.init();
filter();
video();
scroll();
contact();
carousel.init();
slickCarousel.init();
slideshow.init();
tabs.init();
cookie_consent.init();
chat();
form();

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);