function setCookie(name, value, days) {
  const expires = new Date();
  expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const cookie_consent = {
  init: function() {
    document.addEventListener('DOMContentLoaded', function() {
      const banner = document.getElementById('js-cookie-consent-banner');
      const acceptButton = document.getElementById('js-accept-cookies');
      const acceptAllButton = document.getElementById('js-accept-all-cookies');
      const declineButton = document.getElementById('js-decline-cookies');
      const toggleGroup = document.querySelector('.toggle-group');

      if (!getCookie('cookie_consent') || !getCookie('cookie_settings')) {
          banner.style.display = 'block';
      }

      acceptAllButton.addEventListener('click', function() {
          setCookie('cookie_consent', 'accepted', 365);

          toggleGroup.querySelectorAll('input[type="checkbox"]').forEach(toggle => {
            toggle.checked = true;
          });

          const toggleValues = {
            "necessary": true,
            "statistics": true,
            "marketing": true
          };

          setCookie('cookie_settings', JSON.stringify(toggleValues), 365);

          banner.style.display = 'none';
      });

      acceptButton.addEventListener('click', function() {
        setCookie('cookie_consent', 'accepted', 365);

        const toggles = Array.from(toggleGroup.querySelectorAll('input[type="checkbox"]'));

        const toggleValues = {};
        toggles.forEach(toggle => {
            let id = toggle.id.replace('js-','').replace('-toggle','');
            toggleValues[id] = toggle.checked;
        });

        setCookie('cookie_settings', JSON.stringify(toggleValues), 365);

        banner.style.display = 'none';
      });

      declineButton.addEventListener('click', function() {
          setCookie('cookie_consent', 'declined', 365);

          toggleGroup.querySelectorAll('input[type="checkbox"]').forEach(toggle => {
            if( toggle.id != "js-necessary-toggle" ) {
              toggle.checked = false;
            }
          });

          const toggleValues = {
            "necessary": true,
            "statistics": false,
            "marketing": false
          };

          setCookie('cookie_settings', JSON.stringify(toggleValues), 365);

          banner.style.display = 'none';
      });
    });
  }
}

export default cookie_consent;