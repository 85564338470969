const tabs = {
  init() {
    const tabEls = document.querySelectorAll('[data-tab-value]');
    const tabsInfos = document.querySelectorAll('[data-tab-info]');
    tabEls.forEach(tab => {
      tab.addEventListener('click', () => {
        const target = document.querySelector(tab.dataset.tabValue);
    
        tabsInfos.forEach(tabInfo => {
          tabInfo.classList.remove('active');
        });
    
        tabEls.forEach(tab => {
          tab.classList.remove('active-tab');
        });
        
        target.classList.add('active');
        tab.classList.add('active-tab');
      });
    });
  }
};

export default tabs;