import barba from '@barba/core';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
const filterSection = document.getElementById('filters');

function ajax(options) {
  const { url, paginate = false } = options;
  const body = document.body;
  // Add css class - temporarily disable further clicks on filters and cards
  body.classList.add('loading-ajax-cards');

  barba.request(url).then((res) => {
    barba.history.add(url, 'barba');
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res, "text/html");
    const newContainer = resHTML.getElementById('ajax-cards');
    const newContent = newContainer.innerHTML;
    const ajaxContainer = document.getElementById('ajax-cards');
    // Prevent height 'jump' when swapping content
    const height = ajaxContainer.offsetHeight;
    ajaxContainer.style.height = `${height}px`;
    ajaxContainer.innerHTML = newContent;
    ajaxContainer.style.height = `auto`;

    gsap.from('.js-ajax-card', {
      opacity: 0,
      y: 10,
      duration: 0.5,
      delay: 0.2,
      stagger: 0.1,
    });

    if (paginate) {
      console.log('paginate');
      gsap.to(window, {
        scrollTo: {
          y: filterSection,
          offsetY: () => {
            const header = document.getElementById('header');
            const headerHeight = header.offsetHeight;
            return headerHeight;
          }
        },
        duration: 0.2,
      })
    }
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    body.classList.remove('loading-ajax-cards');
  });
}

function updateFilters() {
  const checked = Array.from(document.querySelectorAll('.js-filter:checked'));
  const href = window.location.href;
  // Always remove page number when updating filters
  let url = href.includes('/page') ? href.split('/page')[0] : href.split('?filters')[0];
  if (checked.length) {
    const qString = checked.reduce((p, c, i) => {
      return p + c.value + `${i < checked.length - 1 && checked.length > 1 ? ',' : ''}`;
    }, '?filters=');
    url = url + qString;
  }
  ajax({url: url});
}

function resetFilters() {
  const filters = Array.from(document.querySelectorAll('.js-filter'));
  filters.forEach((filter) => filter.checked = false);
  const href = window.location.href;
  // // Always remove page number when updating filters
  let url = href.includes('/page') ? href.split('/page')[0] : href.split('?filters')[0];
  ajax({url: url});
}

function paginate(link) {
  const url = decodeURIComponent(link.href);
  ajax({
    url: url,
    paginate: true
  });
}

export default function() {
  document.addEventListener('click', function(e) {
    // Allow command-click and control-click to open new tab
    // http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
    if (event.metaKey || event.ctrlKey) {
      return;
    }
    const clicked = e.target;
    const paginateLink = clicked.closest('.js-filter-paginate-link');
    const resetButton = clicked.closest('#reset-filters');
    if (paginateLink) {
      e.preventDefault();
      paginate(paginateLink);
    } else if (resetButton) {
      resetFilters();
    }
  });

  document.addEventListener('change', function(e) {
    const target = e.target;
    const filter = target.closest('.js-filter');
    if (filter) {
      e.stopPropagation();
      updateFilters();
    }
  });
}
