export default function(barbaNext) {
  if (!barbaNext) return;

  const body = barbaNext.container.parentNode;
  const scripts = body.querySelectorAll('script');
  const form = body.querySelector('[id*="caHomeTutoringContact"]');
  const formScript = Array.from(scripts).filter(script => {
    return script.src.includes('formie');
  })[0];
  formScript.remove();
  const newScript = document.createElement('script');
  newScript.src = formScript.src;
  newScript.defer = true;
  form.parentNode.append(newScript);
}