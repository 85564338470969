import gsap from 'gsap';
const button = document.getElementById('mobile-nav-button');
const body = document.body;
const activeClass = 'mobile-nav-is-active';

function hide() {
  const nav = document.getElementById('mobile-nav');
  body.classList.remove(activeClass);
  gsap.to(nav, {
    xPercent: 100,
    duration: 0.2,
    ease: 'none'
  });

  backToMain();
}

function show() {
  const nav = document.getElementById('mobile-nav');
  body.classList.add(activeClass);
  gsap.to(nav, {
    xPercent: -100,
    duration: 0.2,
  })
}

function toggle() {
  if (body.classList.contains(activeClass)) {
    hide();
  } else {
    show();
  }
}

function handleSubnav(e) {
  const target = e.target;
  const selector = target.dataset.subnav;
  const subnav = document.getElementById(selector);
  subnav.classList.add('subnav-is-active');
}

function backToMain() {
  const activeSubnav = document.querySelector('.subnav-is-active');
  if (activeSubnav) {
    activeSubnav.classList.remove('subnav-is-active');
  }
}

const mobile = {
  init: function() {
    document.addEventListener('click', function(e) {
      const clicked = e.target;
      const button = clicked.closest('#mobile-nav-button');
      const subnavButton = clicked.closest('.js-mobile-subnav-button');
      const backButton = clicked.closest('.js-mobile-subnav-back');

      if (button) {
        toggle(); 
      } else if (subnavButton) {
        handleSubnav(e)
      } else if (backButton) {
        backToMain();
      }
    });
  },
  hide: hide
}

export default mobile;
