import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

function handleLink(href) {
  const target = document.querySelector(href);
  if (!target) return;
  
  gsap.to(window, {
    duration: 0.5,
    scrollTo: target,
  });
}

export default function() {
  document.addEventListener('click', function(e) {
    const link = e.target.closest('.js-anchor');
    if (link) {
      const href = link.getAttribute('href');
      if (href.slice(0,1) === '#') {
        e.preventDefault();
        handleLink(href);
      };
    };
  });
}