export default function() {
  // Check if we're on the right CA page
  // Use JS to inject FB chat script
  if (location.pathname.includes('ca/home-tutoring')) {
    window.fbAsyncInit = function() {
      FB.init({
        xfbml: true,
        version: 'v18.0'
      });
    };

    let d = document;
    let s = 'script';
    let id = 'facebook-jssdk';
    let js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
    fjs.parentNode.insertBefore(js, fjs);
  }
}
