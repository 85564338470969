import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import gsap from 'gsap';
import { pathToRegexp, match, parse, compile } from "path-to-regexp";
import carousel from './carousel';
import dropdown from './dropdown';
import mobile from './mobile';
import tabs from './tabs';
import heroVideo from './hero-video';
import chat from './chat';
import form from './form';

// tell Barba to use the prefetch plugin
barba.use(barbaPrefetch);

const pageAnimEl = document.getElementById('page-anim');
const body = document.body;

function checkHeaderFooter(data) {
  // Header normally persists when Barba is 'ajaxing' between pages.
  // If user switches site - e.g. - from US to UK - then nav URLs will be incorrect.
  // So we need to manually update the header contents if the user does so.
  const parser = new DOMParser();
  // Barba 'current' page
  const currentHTML = parser.parseFromString(data.current.html, "text/html");
  const currentHeader = currentHTML.getElementById('header');
  const currentHeaderContainer = document.getElementById('header-container');
  const currentSite = currentHeaderContainer.dataset.site;
  // Barba 'new' page
  const newHTML = parser.parseFromString(data.next.html, "text/html");
  const newHeader = newHTML.querySelector('#header');
  const newHeaderContainer = newHTML.querySelector('#header-container');
  const newFooter = newHTML.querySelector('#footer');
  const newSite = newHeaderContainer.dataset.site;
  // As we never replace the header element itself, need to make sure we manually update classes when ajaxing.
  currentHeader.classList.add(...newHeader.classList);
  // If sites are different update header contents - which will also update the data-site attribute for the next time.
  // Target actual header and footer currently in the DOM
  const header = document.getElementById('header');
  const footer = document.getElementById('footer');
  if (currentSite !== newSite) {
    header.innerHTML = newHeader.innerHTML;
    footer.innerHTML = newFooter.innerHTML;
  }
  // if yellow hero, force white nav
  if( data.next.html.indexOf('entry-hero') > -1 ) {
    header.classList.add("collapsed-nav");
  }
}

function initResizeListener() {
  // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/
  let ticking = false;

  window.addEventListener('resize', function(e) {
    if (!ticking) {
      window.requestAnimationFrame(function() {
        // Reset page animation if user resizes page during animation
        resetAnim();
        ticking = false;
      });

      ticking = true;
    }
  });
}

function resetAnim() {
  const activeAnim = gsap.getTweensOf(pageAnimEl)[0];
  if (!activeAnim) {
    return;
  }
  activeAnim.progress(0);
}

function leaveAnim() {
  return gsap.fromTo(pageAnimEl, {
    display: 'block',
    xPercent: -100,
    "clip-path": "polygon(0 0, 75% 0, 100% 100%, 0% 100%)",
  }, {
    xPercent: 100,
    "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
    duration: 0.5,
    ease: 'power2.out',
    immediateRender: false
  });
}

function afterAnim() {
  return gsap.fromTo(pageAnimEl, {
    xPercent: 100,
  }, {
    xPercent: 200,
    display: 'none',
    duration: 0.5,
    ease: 'power2.out',
    immediateRender: false
  });
}

export default function() {
  body.classList.remove('no-js');

  document.addEventListener('DOMContentLoaded', function(e) {
    body.classList.remove('page-is-loading');
  });

  initResizeListener();

  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  const cacheIgnore = pathToRegexp("/news\\?filters=(.*)");

  let scrollY = 0;
  barba.hooks.beforeLeave((data) => {
    scrollY = window.scrollY;
    dropdown.hide();
    mobile.hide();
  });

  barba.hooks.after((data) => {
    const { trigger, current, next } = data;
    if (trigger === 'back' || trigger === 'forward' || trigger === 'popstate') {
      if (current.url.href.includes('news?filters') && next.url.href.includes('news?filters')) {
        // Keep scroll position when filtering
        window.scrollTo(0, scrollY);
      }
    } else {
      // Reset scroll if navigating to new page
      window.scrollTo(0, 0);
    }

    checkHeaderFooter(data);
    // Re-run any initialising scripts that depend on elements being present on the page (as opposed to click events):
    heroVideo();
    carousel.init();
    tabs.init();
    chat();
    form(next);
  });

  barba.init({
    prefetchIgnore: pathToRegexp(['/', '/uk', '/ca']),
    timeout: 60000,
    requestError: (trigger, action, url, response) => {
      console.log('Response timeout, force-loading new URL...');
      console.log(url);
      // Barba will redirect to the requested url if a prefetch timeout occurs
    },
    transitions: [
      {
        once(data) {
          // Need to append the Craft geomate param "__geom=✪" as a search param to the URL on initial page load, otherwise back button doesn't work if user navigates between sites using
          const searchParams = new URLSearchParams(window.location.search)
          searchParams.set("__geom", "✪");
          const newUrl = window.location.origin + window.location.pathname + '?' + searchParams.toString();
          history.replaceState(null, '', newUrl);
        }
      },
      {
        name: 'opacity-transition',
        beforeLeave() {
          body.classList.add('page-is-changing');
        },
        leave: leaveAnim,
        beforeEnter() {
          body.classList.remove('page-is-changing');
        },
        after: afterAnim
      },
      {
        name: 'self',
        enter() {
          body.classList.remove('loading-ajax-cards');
          gsap.from('.js-ajax-card', {
            opacity: 0,
            y: 10,
            duration: 0.5,
            delay: 0.2,
            stagger: 0.1,
          });
        },
        beforeLeave() {
          body.classList.add('loading-ajax-cards');
        }
      }
    ],
    cacheIgnore: [cacheIgnore],
  });
}
